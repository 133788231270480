@use "reset";
@use "spacing" as *;
@use "typography" as *;
@use "admonition";
@use "colors" as *;
@use "fragments";
@use "components";

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header,
.content,
.footer {
  padding-left: $base-inline-margin;
  padding-right: $base-inline-margin;
}

.content {
  flex-grow: 1;
  margin: 1rem auto;

  &.text-content {
    max-width: 80ch;
  }
}

.footer {
  display: flex;

  .copyright {
    margin-left: auto;
  }
}

code {
  border-radius: $border-radius;
}

dl {
  margin-bottom: 1rem;

  dt {
    font-weight: 600;
  }

  dd {
    margin-left: 1rem;
  }
}
